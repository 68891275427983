<template>
  <div>
    <b-button
      size="sm"
      class="button-export-style ml-2 mr-2"
      title="Modifier les colonnes "
      v-b-modal.updateColumns
    >
      <font-awesome-icon icon="pen" />
    </b-button>
    <b-modal
      id="updateColumns"
      ref="updateColumns"
      title="Modifier colonnes appel à paiement"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-commercial "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier colonnes appel à paiement</div>
        <div class="iconClose" @click.prevent="hideModal('updateColumns')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleUpdateColumns"
            class="form-modal-custom-style"
          >
            <b-row>
              <b-col cols="1"></b-col>
              <b-col cols="4">
                <b-form-group
                  label="Nom du colonne"
                  label-for="month"
                  class="input-modal-champ type-doc-class"
                >
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group label="Valeur : " label-for="valeur" class="mr-2">
                </b-form-group>
              </b-col>
            </b-row>
            <template v-if="updateAll">
              <b-row>
                <b-col cols="1">
                  <b-form-checkbox
                    v-model="checkSousRegie"
                    name="some-radios"
                    value="sous_regie"
                    class="mt-2 check-th-details"
                    @change="checkRequiredSousRegie"
                  ></b-form-checkbox>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Sous régie"
                    label-for="month"
                    class="input-modal-champ"
                  >
                  </b-form-group
                ></b-col>
                <b-col cols="7">
                  <b-form-select
                    class="b-form-select-raduis"
                    :required="requiredSousRegie"
                    v-model="sous_regie"
                    :options="optionsSelectColumnSousRegie"
                    text-field="full_name"
                    value-field="id"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="1">
                  <b-form-checkbox
                    v-model="checkResteaCharge"
                    name="some-radios"
                    value="commercial_reste_a_charge"
                    @change="checkRequiredResteACharge"
                    class="mt-2 check-th-details"
                  ></b-form-checkbox>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Commercial reste à charge"
                    label-for="month"
                    class="input-modal-champ"
                  >
                  </b-form-group>
                </b-col>
                <b-col cols="7">
                  <b-form-input
                    autocomplete="off"
                    v-model="commercial_reste_a_charge"
                    type="number"
                    step="any"
                    :required="requiredresteCharge"
                    class="b-form-input-raduis"
                    min="0"
                    oninput="validity.valid||(value < 0 || value.length==0? value = '' : value =value);"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="1">
                  <b-form-checkbox
                    v-model="checkAgentCommercial"
                    name="some-radios"
                    value="agent_commercial_terrain"
                    @change="checkRequiredAgentCommercial"
                    class="mt-2 check-th-details"
                  ></b-form-checkbox>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Agent commercial terrain"
                    label-for="month"
                    class="input-modal-champ"
                  >
                  </b-form-group>
                </b-col>
                <b-col cols="7">
                  <b-form-select
                    class="b-form-select-raduis"
                    :required="requiredAgentCommercial"
                    v-model="agent_commercial_terrain"
                    :options="optionsSelectColumnCommercial"
                    text-field="full_name"
                    value-field="id"
                  ></b-form-select>
                </b-col>
              </b-row>
            </template>
            <b-row>
              <b-col cols="1">
                <b-form-checkbox
                  v-model="checkStatut"
                  name="some-radios"
                  value="statut_appel_commercial"
                  @change="checkRequiredStatut"
                  class="mt-2 check-th-details"
                ></b-form-checkbox>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Statut appel à paiement"
                  label-for="month"
                  class="input-modal-champ"
                >
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-select
                  class="b-form-select-raduis"
                  :required="requiredStatut"
                  v-model="statut_appel_commercial"
                  :options="optionsSelectStatut"
                  text-field="full_name"
                  value-field="id"
                ></b-form-select>
              </b-col>
            </b-row>

            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style ml-2"
                @click="hideModal('updateColumns')"
              >
                <span> Annuler </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'update-multiple-column-appel-paiement',
  props: {
    dataToUse: { required: true },
    generateFunction: { required: true },
    optionsSelectColumnSousRegie: { required: true },
    optionsSelectColumnCommercial: { required: true },
    optionsSelectStatut: { required: true },
    updateAll: { required: true }
  },
  data() {
    return {
      sous_regie: null,
      commercial_reste_a_charge: null,
      agent_commercial_terrain: null,
      statut_appel_commercial: null,
      error: [],
      loading: false,
      checkSousRegie: null,
      checkResteaCharge: null,
      checkAgentCommercial: null,
      checkStatut: null,
      requiredSousRegie: false,
      requiredresteCharge: false,
      requiredAgentCommercial: false,
      requiredStatut: false
    };
  },
  methods: {
    checkRequiredStatut() {
      this.error = [];
      this.requiredStatut =
        this.checkStatut == 'statut_appel_commercial' ? true : false;
      this.statut_appel_commercial =
        this.requiredStatut == false ? null : this.statut_appel_commercial;
    },
    checkRequiredAgentCommercial() {
      this.error = [];
      this.requiredAgentCommercial =
        this.checkAgentCommercial == 'agent_commercial_terrain' ? true : false;
      this.agent_commercial_terrain =
        this.requiredAgentCommercial == false
          ? null
          : this.agent_commercial_terrain;
    },
    checkRequiredResteACharge() {
      this.error = [];
      this.requiredresteCharge =
        this.checkResteaCharge == 'commercial_reste_a_charge' ? true : false;
      this.commercial_reste_a_charge =
        this.requiredresteCharge == false
          ? null
          : this.commercial_reste_a_charge;
    },
    checkRequiredSousRegie() {
      this.error = [];
      this.requiredSousRegie =
        this.checkSousRegie == 'sous_regie' ? true : false;
      this.sous_regie = this.checkSousRegie == false ? null : this.sous_regie;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleUpdateColumns() {
      this.error = [];
      this.loading = true;
      if (
        this.checkResteaCharge != 'commercial_reste_a_charge' &&
        this.checkSousRegie != 'sous_regie' &&
        this.checkAgentCommercial != 'agent_commercial_terrain' &&
        this.checkStatut != 'statut_appel_commercial'
      ) {
        this.loading = false;
        this.error = ['Sélectionner au moins une colonne à modifier'];
      } else {
        const response = await this.generateFunction({
          ids: this.dataToUse,
          commercial_reste_a_charge: this.commercial_reste_a_charge,
          sous_regie: this.sous_regie,
          agent_commercial_terrain: this.agent_commercial_terrain,
          statut_appel_commercial: this.statut_appel_commercial
        });
        if (response.success) {
          this.loading = false;
          this.$emit('reloadData');
          this.hideModal('updateColumns');
        } else {
          this.loading = false;
          this.error = response.error;
        }
      }
    },
    resetModal() {
      this.sous_regie = null;
      this.commercial_reste_a_charge = null;
      this.agent_commercial_terrain = null;
      this.error = [];
      this.loading = false;
      this.checkSousRegie = false;
      this.checkResteaCharge = false;
      this.checkAgentCommercial = false;
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue')
  }
};
</script>
